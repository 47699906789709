// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-branding-tsx": () => import("./../../../src/pages/Branding.tsx" /* webpackChunkName: "component---src-pages-branding-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-graphic-design-tsx": () => import("./../../../src/pages/GraphicDesign.tsx" /* webpackChunkName: "component---src-pages-graphic-design-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketing-seo-tsx": () => import("./../../../src/pages/MarketingSEO.tsx" /* webpackChunkName: "component---src-pages-marketing-seo-tsx" */),
  "component---src-pages-photography-tsx": () => import("./../../../src/pages/Photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */),
  "component---src-pages-showreel-tsx": () => import("./../../../src/pages/showreel.tsx" /* webpackChunkName: "component---src-pages-showreel-tsx" */),
  "component---src-pages-social-content-tsx": () => import("./../../../src/pages/SocialContent.tsx" /* webpackChunkName: "component---src-pages-social-content-tsx" */),
  "component---src-pages-studio-tsx": () => import("./../../../src/pages/studio.tsx" /* webpackChunkName: "component---src-pages-studio-tsx" */),
  "component---src-pages-stuxio-tsx": () => import("./../../../src/pages/stuxio.tsx" /* webpackChunkName: "component---src-pages-stuxio-tsx" */),
  "component---src-pages-three-d-tsx": () => import("./../../../src/pages/threeD.tsx" /* webpackChunkName: "component---src-pages-three-d-tsx" */),
  "component---src-pages-ui-ux-tsx": () => import("./../../../src/pages/UiUx.tsx" /* webpackChunkName: "component---src-pages-ui-ux-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-website-tsx": () => import("./../../../src/pages/Website.tsx" /* webpackChunkName: "component---src-pages-website-tsx" */)
}

